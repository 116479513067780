import { FC, ReactNode } from "react";
import classNames from "classnames";

export interface AboutLineProps {
  href?: string;
  Icon?: FC;
  label?: string;
  children: ReactNode;
  className?: string;
}

const AboutLine: FC<AboutLineProps> = ({
  href,
  Icon,
  label,
  children,
  className,
}) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={classNames(
        "flex items-center w-full mb-2",
        href && "underline",
        className
      )}
    >
      <span className="w-8 h-8 shrink-0 flex items-center justify-center mr-2 mt-2 self-baseline">
        {Icon && <Icon />}
      </span>
      <div className="flex-1 min-w-0">
        {label && <div className="text-grey-500 text-sm">{label}</div>}
        {children}
      </div>
    </a>
  );
};

export default AboutLine;
