import { FC, useState } from "react";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import useClientAssignee from "@hooks/use-client-assignee";
import useTodos from "@hooks/use-todos";
import useTodosFilter from "@hooks/use-todos-filter";
import { AccountType } from "@lib/data/schemas/account";
import { ClientType } from "@lib/data/schemas/client";

import CollapsedHeader from "@components/DisplayDetails/CollapsedHeader";
import EmptyTodoIconShared from "@components/Icons/EmptyTodoIconShared";
import Todos from "@components/Todos/Todos";
import TodosMoreMenu from "@components/Todos/TodosMoreMenu";

import TodoEmptyState from "./TodoEmptyState";
import TodoTags from "./TodoTags";

interface SharedTodosProps {
  className?: string;
  coachId: string;
  client: ClientType;
  isCollapsible?: boolean;
  isDefaultCollapsed?: boolean;
  title?: string;
  messaging?: boolean;
}

const SharedTodos: FC<SharedTodosProps> = ({
  className,
  coachId,
  client,
  isCollapsible = false,
  isDefaultCollapsed = false,
  title = "Goals",
  messaging = false,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(isDefaultCollapsed);
  const { oid } = useAuth();
  const { todos, createTodo, loading } = useTodos({
    coachUserId: coachId,
    contactId: client.id,
  });
  const variant = messaging ? "messaging" : "shared";
  const isClient = oid === client?.accountId;
  const { clientAssignee } = useClientAssignee(
    !client.assigneeIds ? client?.id : undefined,
    coachId
  );

  const assigneeOptions = [client, clientAssignee].filter(Boolean);

  const { SortDropdown, completeTodos, incompleteTodos, filtersState } =
    useTodosFilter(todos, variant, isClient);

  const iconClassNames = classNames(
    "text-action-300",
    messaging && "dark:text-white"
  );

  const header = (
    <div
      className={classNames(
        "flex items-center justify-between py-4 pl-4 text-action-300 w-full",
        messaging && "dark:text-white",
        isCollapsible ? "pr-1.5" : "pr-4"
      )}
    >
      <div className={classNames("flex items-center text-xl max-w-[212px]")}>
        <h1 className="truncate">{title}</h1>
        <TodoTags
          variant={variant}
          client={client}
          customListTodos={incompleteTodos}
        />
      </div>
      {!collapsed && (
        <div className="flex gap-1 items-center">
          <div className="h-8"> {SortDropdown}</div>
          <TodosMoreMenu
            title={title}
            clientId={client.id}
            coachId={coachId}
            className={classNames(
              "bg-action-300/10 rounded-lg hover:bg-action-300/20 h-8 w-8 flex items-center justify-center",
              messaging &&
                "dark:bg-grey-500/20 dark:text-white dark:hover:bg-grey-950/20"
            )}
            iconClassName={iconClassNames}
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      className={classNames(
        "bg-action-950 border-black-ink border-opacity-7 border rounded-xl",
        messaging && "dark:bg-grey-250 dark:border-grey-150",
        className
      )}
    >
      {isCollapsible ? (
        <CollapsedHeader
          collapsed={collapsed}
          toggleCollapsed={setCollapsed}
          leftElement={header}
          className={classNames("pr-4", iconClassNames)}
          disableHeaderClickable
        />
      ) : (
        header
      )}
      {(!isCollapsible || !collapsed) && !loading && (
        <>
          {!incompleteTodos?.length && (
            <TodoEmptyState
              title="Keep track of progress"
              description="Assign tasks and set due dates to ensure nothing gets missed and eliminate duplicate work."
              Icon={EmptyTodoIconShared}
              variant="shared"
              dark={messaging}
            />
          )}
          <Todos
            client={client}
            messaging={messaging}
            completeTodos={completeTodos}
            incompleteTodos={incompleteTodos}
            createTodo={() => createTodo(filtersState)}
            assigneeOptions={assigneeOptions as (AccountType | ClientType)[]}
          />
        </>
      )}
    </div>
  );
};

export default SharedTodos;
