import { useCallback, useState } from "react";
import axios from "axios";

import { useAuth } from "@contexts/auth";
import useContactAppointments from "@hooks/use-contact-appointments";
import { getApptsWithOneRecurring } from "@lib/appointments";
import { NoteType } from "@lib/data/schemas/note";
import { unlinkedAppointmentsForNote } from "@lib/utils/appointments";

type LinkNotesType = (
  appointmentId: string,
  noteIds: string[],
  action: "add" | "remove"
) => Promise<void>;

type UseAppointmentLinkNotes = (props: {
  oid: string | undefined;
  contactId: string | undefined;
  apiVersion?: string;
}) => {
  isLoadingLink: boolean;
  isLoadingUnlink: boolean;
  linkNotesError?: string;
  unlinkNotesError?: string;
  linkNotes: LinkNotesType;
};

const useAppointmentLinkNotes: UseAppointmentLinkNotes = ({
  oid,
  contactId,
  apiVersion = "v1",
}) => {
  const [isLoadingLink, setIsLoadingLink] = useState<boolean>(false);
  const [isLoadingUnlink, setIsLoadingUnlink] = useState<boolean>(false);
  const [linkNotesError, setLinkNotesError] = useState<string>("");
  const [unlinkNotesError, setUnlinkNotesError] = useState<string>("");

  const handleLoadingLink = () => {
    setIsLoadingLink(true);
    setLinkNotesError("");
  };

  const handleLoadingUnlink = () => {
    setIsLoadingUnlink(true);
    setUnlinkNotesError("");
  };

  const linkNotes: LinkNotesType = async (appointmentId, noteIds, action) => {
    const isActionAdd = action === "add";
    if (isActionAdd) {
      handleLoadingLink();
    } else {
      handleLoadingUnlink();
    }
    try {
      const baseApiUrl = `/api/${apiVersion}/users/${oid}/appointments/${appointmentId}`;
      await axios.post(`${baseApiUrl}/link-notes`, {
        action,
        clientId: contactId,
        noteIds,
      });
      if (isActionAdd) {
        setIsLoadingLink(false);
      } else {
        setIsLoadingUnlink(false);
      }
    } catch (err) {
      if (isActionAdd) {
        setIsLoadingLink(false);
        setLinkNotesError("Something get wrong!");
      } else {
        setIsLoadingUnlink(false);
        setUnlinkNotesError("Something get wrong!");
      }
    }
  };

  return {
    isLoadingLink,
    isLoadingUnlink,
    linkNotesError,
    unlinkNotesError,
    linkNotes,
  };
};

export const useGetLinkedOrUnlinkedAppointmentsForNote = (clientId: string) => {
  const { oid } = useAuth();
  const { appointments } = useContactAppointments({ oid, contactId: clientId });

  const getLinkedAppointmentsForNote = useCallback(
    (note: NoteType) =>
      appointments.filter((appt) => note.appointmentIds?.includes(appt.id)),
    [appointments]
  );

  const getUnlinkedAppointmentsForNote = useCallback(
    (noteId: string) =>
      unlinkedAppointmentsForNote(
        noteId,
        getApptsWithOneRecurring(appointments)
      ),
    [appointments]
  );

  return {
    getLinked: getLinkedAppointmentsForNote,
    getUnlinked: getUnlinkedAppointmentsForNote,
  };
};

export default useAppointmentLinkNotes;
