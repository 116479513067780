import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="80"
      height="57"
      viewBox="0 0 80 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M48.1667 44.2596C42.154 44.2596 37.2798 49.1028 37.2798 55.0771H22C22 40.7177 33.7152 29.0771 48.1667 29.0771C62.6181 29.0771 74.3333 40.7177 74.3333 55.0771H59.0535C59.0535 49.1028 54.1793 44.2596 48.1667 44.2596Z"
          fill="#8B5114"
        />
        <path
          d="M44.1347 18.5407L12.206 53.2421L0.979519 42.9127L32.9082 8.21126L44.1347 18.5407Z"
          fill="#8B5114"
          fillOpacity="0.25"
        />
        <path
          d="M55.3904 3.51845L78.8458 24.6855L68.6251 36.0111L45.1697 14.8441L55.3904 3.51845Z"
          fill="#8B5114"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_5844_40622">
          <rect
            width="80"
            height="56"
            fill="white"
            transform="translate(0 0.12793)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
