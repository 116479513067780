import { useEffect } from "react";
import axios from "axios";
import { orderBy } from "lodash";

import usePersistentState from "./use-persistent-state";

interface Country {
  code: string;
  name: string;
}

// this is necessary because the json file used in this code do not contain
// the countries added in this list
const notIncludedInJson = [
  { code: "GB-ENG", name: "England" },
  { code: "GB-SCT", name: "Scotland" },
  { code: "GB-NIR", name: "Northern Ireland" },
];

export default function useCountries(): Array<Country> {
  const { persistentValue, persistentSetValue } = usePersistentState<Country[]>(
    "countries",
    []
  );

  useEffect(() => {
    const getCountries = async () => {
      const countriesResponse = await axios.get<
        Array<{ iso2: string; name: string }>
      >(
        "https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/json/countries.json"
      );
      if (countriesResponse.data) {
        const countriesToStore = countriesResponse.data.map((country) => ({
          code: country.iso2,
          name: country.name,
        }));
        persistentSetValue(
          orderBy([...countriesToStore, ...notIncludedInJson], ["name"])
        );
      }
    };

    // @TODO: maybe we should make this logic to work with incremental items
    //        added on the `notIncludedInJson`
    const isSavedNewCode = !!persistentValue?.find((item) =>
      notIncludedInJson.map((n) => n.code).includes(item.code)
    );

    if ((persistentValue && persistentValue.length === 0) || !isSavedNewCode) {
      getCountries();
    }
  }, []);

  return persistentValue as Array<Country>;
}
