import usePersistentState from "./use-persistent-state";

export type SortyByType =
  | "all"
  | "appointments"
  | "emails"
  | "files"
  | "forms"
  | "invoices"
  | "links"
  | "notes";

interface UseClientPersistentDataType {
  controls: {
    sortBy: SortyByType;
    isAboutSectionOpen?: boolean;
    isUpcomingSectionOpen?: boolean;
  };
}

type UseClientPersistentType = (clientId: string) => {
  controls: UseClientPersistentDataType["controls"];
  updateSortBy: (sortBy: SortyByType) => void;
  toggleAboutSection: () => void;
  toggleUpcomingSection: () => void;
};

export const initialState: UseClientPersistentDataType = {
  controls: {
    sortBy: "all",
    isAboutSectionOpen: false,
    isUpcomingSectionOpen: false,
  },
};

const useClientPersistentState: UseClientPersistentType = (clientId) => {
  const { persistentValue = initialState, persistentSetValue } =
    usePersistentState(clientId, initialState);
  const controls = persistentValue
    ? { ...persistentValue?.controls }
    : {
        ...initialState.controls,
      };

  const updateSortBy = (sortBy: SortyByType) => {
    persistentSetValue({
      ...persistentValue,
      controls: {
        ...controls,
        sortBy,
      },
    });
  };

  const toggleAboutSection = () => {
    persistentSetValue({
      ...persistentValue,
      controls: {
        ...controls,
        isAboutSectionOpen: !controls?.isAboutSectionOpen,
      },
    });
  };

  const toggleUpcomingSection = () => {
    persistentSetValue({
      ...persistentValue,
      controls: {
        ...controls,
        isUpcomingSectionOpen: !controls?.isUpcomingSectionOpen,
      },
    });
  };

  return {
    controls,
    updateSortBy,
    toggleAboutSection,
    toggleUpcomingSection,
  };
};

export default useClientPersistentState;
