import { FC, useMemo } from "react";
import classNames from "classnames";
import { compact, isEmpty } from "lodash";
import Link from "next/link";

import useCountries from "@hooks/use-countries";
import useGroups from "@hooks/use-groups";
import { getTimezoneLabel } from "@hooks/use-timezones";
import { ClientType } from "@lib/data/schemas/client";
import { GroupType } from "@lib/shared-types";
import { getContactEmails } from "@lib/utils";
import pluralHelper from "@lib/utils/pluralHelper";

import AboutCustomFields from "@components/About/AboutCustomFields";
import AboutLine from "@components/About/AboutLine";
import EmailIcon from "@components/Icons/EmailIcon";
import GroupIcon from "@components/Icons/GroupIcon";
import LocationIcon from "@components/Icons/LocationIcon";
import LockIcon from "@components/Icons/LockIcon";
import MailIcon from "@components/Icons/MailIcon";
import PhoneIcon from "@components/Icons/PhoneIcon";
import TimezoneIcon from "@components/Icons/TimezoneIcon";

export interface AboutListProps {
  className?: string;
  client: ClientType;
  coachId: string;
  fromMessaging?: boolean;
}

const AboutList: FC<AboutListProps> = ({
  className,
  client,
  coachId,
  fromMessaging = false,
}) => {
  const { getClientGroups } = useGroups(coachId);
  const clientGroups = getClientGroups(client.id);
  //following variables used to determine showing location or not
  const street = client.address?.line1;
  const zip = client.address?.postalCode;
  const city = client.address?.city;
  const state = client.address?.state;
  const email = client.address?.email;
  const countries = useCountries();
  const country = useMemo(() => {
    const countryWithName = countries.find(
      (country) => country.code === client.address?.country
    );

    return countryWithName?.name || client.address?.country || null;
  }, [client.address?.country, countries]);

  const hasFullAddress = !!(street && city && state && zip && country);
  const hasPartialAddress = !!(street || city || state || country);

  return (
    <div className={classNames(className)}>
      {client.clientType === "individual" &&
        getContactEmails(client).map((email: string) => (
          <AboutLine
            className="break-all"
            key={email}
            Icon={MailIcon}
            href={`mailto:${email}`}
          >
            {email}
          </AboutLine>
        ))}
      {client.phoneNumber && (
        <AboutLine Icon={PhoneIcon} href={`tel:${client.phoneNumber}`}>
          {client.phoneNumber}
        </AboutLine>
      )}
      {client.timeZone && client.clientType === "individual" && (
        <AboutLine Icon={TimezoneIcon}>
          {getTimezoneLabel(client.timeZone).long}
        </AboutLine>
      )}
      {clientGroups && !isEmpty(clientGroups) && (
        <AboutLine
          label={pluralHelper(clientGroups.length, "Group")}
          Icon={GroupIcon}
        >
          {clientGroups.map((group: GroupType) => (
            <div key={group.id}>
              <Link href={`/groups/${group.id}`} className="underline">
                {group.title}
              </Link>
            </div>
          ))}
        </AboutLine>
      )}
      <AboutCustomFields coachId={coachId} client={client} />
      {(hasFullAddress || hasPartialAddress) && (
        <div className="pt-4 border-t mt-2 group-hover:border-grey-800">
          {client.members ? (
            <AboutLine label={"Billing information"} Icon={EmailIcon}>
              {email}
            </AboutLine>
          ) : null}
          {hasFullAddress ? (
            <AboutLine Icon={LocationIcon}>
              {`
             ${street}
            `}
              <br></br>
              {`
             ${city} ${state} ${zip}
            `}
              <br></br>
              {`
             ${country}
            `}
            </AboutLine>
          ) : (
            hasPartialAddress && (
              <AboutLine Icon={LocationIcon}>
                {compact([street, city, state, zip, country]).join(" ")}
              </AboutLine>
            )
          )}
        </div>
      )}
      <div
        className={classNames(
          "rounded-md flex items-center text-grey-300 text-sm pt-4 ml-4 lg:ml-2",
          fromMessaging && "dark:text-grey-950"
        )}
      >
        <>
          <LockIcon className="w-3 h-3" />
          <div
            className={classNames(
              "ml-4 lg:ml-2 lg:whitespace-nowrap",
              fromMessaging && "dark:text-grey-950"
            )}
          >
            The about section is visible only to you
          </div>
        </>
      </div>
    </div>
  );
};

export default AboutList;
