import { FC } from "react";
import classNames from "classnames";

import useAccessType from "@hooks/use-access-type";
import useClientAssignee from "@hooks/use-client-assignee";
import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";

import Avatar from "@components/Client/ClientAvatar";
import { ConditionalTeamsRenderer } from "@components/ConditionalTeamsRenderer";

interface ClientAssigneeProps {
  className?: string;
  assignee: AccountType;
  loading?: boolean;
  onClick?: () => void;
  enableDarkMode?: boolean;
}

const ClientAssignee: FC<ClientAssigneeProps> = ({
  className,
  assignee,
  loading = false,
  onClick,
  enableDarkMode = false,
}) => {
  const style = "bg-grey-900 dark:bg-grey-250 animate-pulse";
  return loading ? (
    <div className={classNames("flex flex-1 items-center", className)}>
      <div className={classNames("w-6 h-6 rounded-full", style)} />
      <div className={classNames("flex-1 w-full h-8 rounded ml-2", style)} />
    </div>
  ) : (
    <div
      className={classNames(
        "flex flex-1 items-center",
        onClick && "cursor-pointer",
        className
      )}
      onClick={onClick}
    >
      <Avatar size="xsmall" client={assignee} />
      <div className="flex-1 ml-2 truncate">
        <div className="text-xs text-grey-500">Assigned to</div>
        <div
          className={classNames(
            "text-sm text-black-ink overflow-hidden text-ellipsis",
            enableDarkMode && "dark:text-white"
          )}
        >
          {displayNameFromContact(assignee)}
        </div>
      </div>
    </div>
  );
};

interface ClientAssigneeContainerProps {
  clientId: string;
  className?: string;
  onClick?: () => void;
  enableDarkMode?: boolean;
}
export const ClientAssigneeContainer: FC<ClientAssigneeContainerProps> = ({
  clientId,
  className,
  onClick,
  enableDarkMode,
}) => {
  const { clientAssignee, isLoading: isLoadingClientAssinee } =
    useClientAssignee(clientId);
  const { hasFullAccess } = useAccessType();

  return (
    <ConditionalTeamsRenderer>
      <ClientAssignee
        className={classNames(
          "mb-4 border-b border-grey-900 pb-4",
          enableDarkMode && "dark:border-grey-300",
          className
        )}
        assignee={clientAssignee!}
        loading={isLoadingClientAssinee}
        onClick={hasFullAccess ? onClick : undefined}
        enableDarkMode={enableDarkMode}
      />
    </ConditionalTeamsRenderer>
  );
};

export default ClientAssignee;
