import { useMemo } from "react";
import { getContactAppointments } from "api-services/definitions/contact-appointments";
import { useApiGet } from "api-services/endpoints";
import { orderBy } from "lodash";

import { AppointmentWithOrganizerType } from "@lib/appointments";
import { AttendeeType } from "@lib/shared-types";

import { useGetCalendarOwnerCallback } from "@components/availabilities/AvailabilityOwnerPreview";

type DecorateAppointmentsType = (props: {
  appointments: AppointmentWithOrganizerType[];
  contactEmail: string | undefined;
  getCalendarOwner: Function;
}) => AppointmentWithOrganizerType[];

type UseContactAppointmentsType = (props: {
  oid: string | undefined;
  contactId: string | undefined;
  contactEmail?: string;
}) => {
  appointments: AppointmentWithOrganizerType[];
  loading: boolean;
};

const decorateAppointments: DecorateAppointmentsType = ({
  appointments,
  contactEmail,
  getCalendarOwner,
}) => {
  const data = appointments.map((item: AppointmentWithOrganizerType) => {
    const isEvent = !!item?.eventData;
    const attendees = item.gcal?.event?.attendees || [];
    const attendee = attendees.find(
      (att: AttendeeType) => contactEmail && att.email === contactEmail
    );
    const contactStatus = attendee?.responseStatus;
    const gcalEvent = item.gcal?.event;

    const organizer = getCalendarOwner(
      gcalEvent?.organizer?.email || gcalEvent?.creator?.email
    );
    if (organizer) item.organizer = organizer;

    // Since objects retrieved from API calls are casted but not instantiated,
    // we have to cast the dates to Date objects to get access to getTime() method
    return isEvent
      ? {
          ...item,
          status: contactStatus ?? item?.status,
          start: new Date(item?.start),
          end: new Date(item?.end),
        }
      : { ...item, start: new Date(item?.start), end: new Date(item?.end) };
  });
  return orderBy(data || [], ["start"]);
};

const useContactAppointments: UseContactAppointmentsType = ({
  oid,
  contactId,
  contactEmail,
}) => {
  const { data, loading } = useApiGet(
    oid && contactId ? getContactAppointments : undefined,
    {
      orgId: oid ?? "",
      contactId: contactId ?? "",
    },
    {
      contactEmail: contactEmail ?? "",
    }
  );

  const getCalendarOwner = useGetCalendarOwnerCallback();

  const appointments = useMemo(() => {
    if (!contactId) return [];

    return decorateAppointments({
      appointments:
        data?.data?.map((item) => ({
          ...item,
          // z.boolean().default(false) can't be assigned to z.boolean().default(false) ¯\_(ツ)_/¯
          hidden: item.hidden ?? false,
        })) ?? [],
      contactEmail,
      getCalendarOwner,
    });
  }, [contactEmail, contactId, data?.data, getCalendarOwner]);

  return {
    appointments,
    loading,
  };
};

export default useContactAppointments;
