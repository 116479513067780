import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import Link from "next/link";

import useClientPersistentState from "@hooks/use-client-persistent-state";
import { useHasFullAccess } from "@hooks/use-organization-accounts";
import { ClientType } from "@lib/data/schemas/client";

import AboutList from "@components/About/AboutList";
import { Button } from "@components/Button";
import { ClientAssigneeContainer } from "@components/Client/ClientAssignee";
import ClientOrganizationAboutList from "@components/ClientOrganizations/ClientOrganizationAboutList";
import { ConditionalTeamsRenderer } from "@components/ConditionalTeamsRenderer";
import DoubleChevronCloseIcon from "@components/Icons/DoubleChevronCloseIcon";
import DoubleChevronOpenIcon from "@components/Icons/DoubleChevronOpenIcon";

export interface AboutProps {
  className?: string;
  client: ClientType;
  coachId: string;
  onClientAssigneeClick?: () => void;
}

const About: FC<AboutProps> = ({
  className,
  client,
  coachId,
  onClientAssigneeClick,
}) => {
  const { controls, toggleAboutSection } = useClientPersistentState(client.id);
  const isAboutSectionOpen = !!controls?.isAboutSectionOpen;
  const [showExpandControls, setShowExpandControls] = useState(false);
  const hasFullAccess = useHasFullAccess();

  const renderCollapsibleActions = () => {
    const Icon = isAboutSectionOpen
      ? DoubleChevronCloseIcon
      : DoubleChevronOpenIcon;
    return (
      <div
        className={classNames(
          "absolute bottom-0 inset-x-0 text-right",
          showExpandControls &&
            !isAboutSectionOpen &&
            "bg-gradient-to-t from-grey-950"
        )}
      >
        <Button
          className={classNames(
            " mr-4 mb-4 shadow-elevation-50 text-grey-300",
            showExpandControls
              ? "lg:invisible lg:group-hover:visible"
              : "invisible"
          )}
          onClick={toggleAboutSection}
          icon={<Icon />}
          white
          square
          smaller
        />
      </div>
    );
  };

  useEffect(() => {
    const itemCount =
      document.getElementById("about-list")?.getElementsByTagName("a").length ??
      0;
    setShowExpandControls(itemCount > 3);
  }, []);

  return (
    <div
      className={classNames(
        className,
        "flex flex-col bg-grey-950 border border-grey-900 rounded-xl p-5 mt-2 group relative",
        showExpandControls && !isAboutSectionOpen && "overflow-hidden h-[162px]"
      )}
    >
      <div className="absolute top-4 right-4 lg:invisible lg:group-hover:visible">
        {(client.clientType === "individual" || hasFullAccess) && (
          <Link
            href={`/${
              client.clientType === "individual"
                ? "contacts"
                : "client-organizations"
            }/${encodeURIComponent(client.id)}/edit`}
          >
            <Button
              className="shadow-elevation-50 text-sm px-3 text-grey-300"
              component="span"
              white
              square
              small
              data-heap-event-name="client_record_about_section_edit_button_clicked"
            >
              Edit record
            </Button>
          </Link>
        )}
      </div>
      {client && (
        <>
          {client.clientType === "individual" ? (
            <ConditionalTeamsRenderer
              defaultContent={<h2 className="text-xl mb-4">About</h2>}
            >
              <ClientAssigneeContainer
                clientId={client.id}
                onClick={onClientAssigneeClick}
              />
            </ConditionalTeamsRenderer>
          ) : (
            <ClientOrganizationAboutList client={client} coachId={coachId} />
          )}

          <div id="about-list">
            <AboutList client={client} coachId={coachId} />
          </div>
        </>
      )}
      {renderCollapsibleActions()}
    </div>
  );
};

export default About;
