import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import axios from "axios";

import useSnackbar from "@hooks/use-snackbar";
import analytics from "@lib/analytics";

import TextFieldForm from "@components/Form/TextFieldForm";
import ModalWrappedForm from "@components/ModalWrappedForm";

interface RenameModalProps {
  show: boolean;
  toggleShow: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  clientId: string;
  coachId: string;
}

const RenameForm: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <TextFieldForm
      containerClassName="mb-4"
      name="title"
      register={register}
      errors={errors}
    />
  );
};

const RenameModal: FC<RenameModalProps> = ({
  show,
  title,
  toggleShow,
  clientId,
  coachId,
}) => {
  const snackbar = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (data: { title: string }) => {
    const url = `/api/v1/users/${coachId}/todos/list?clientId=${clientId}`;

    setIsSubmitting(true);
    try {
      await axios.patch(url, { title: data.title });
      analytics.track("todo_list_renamed");
      snackbar.showMessage("Todo list renamed");
      toggleShow(false);
    } catch (error) {
      snackbar.showWarning(
        "Error to rename",
        error?.message || "something went wrong"
      );
      analytics.track("todo_list_renamed_error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    /* @ts-expect-error: thinks props are mandatory */
    <ModalWrappedForm
      show={show}
      toggleShow={toggleShow}
      onSubmit={handleSubmit}
      title="Rename"
      actionTitle="Save"
      defaultValues={{ title }}
      mode="onChange"
      isLoading={isSubmitting}
      modalProps={{
        maxWidth: "max-w-sm",
      }}
      hideHeaderSeperator
    >
      <div className="mt-6">
        <RenameForm />
      </div>
    </ModalWrappedForm>
  );
};

export default RenameModal;
