import { FC, ReactNode } from "react";
import { capitalize, isArray, orderBy } from "lodash";
import moment from "moment";

import useCustomFields from "@hooks/use-custom-fields";
import { CUSTOM_FIELD_ICONS } from "@lib/constants/customFieldIcons";
import { ClientType } from "@lib/data/schemas/client";

import AboutLine from "@components/About/AboutLine";
import CompanyIcon from "@components/Icons/CompanyIcon";

export interface AboutCustomFieldsProps {
  client: ClientType;
  coachId: string;
}

const AboutCustomFields: FC<AboutCustomFieldsProps> = ({ client, coachId }) => {
  const { getFieldType, customFields } = useCustomFields({
    coachId: coachId,
  });
  if (!customFields) return null;
  const answers = client.customFields;
  if (!answers) return null;
  const items: ReactNode[] = [];
  orderBy(answers, ["field"], ["asc"]).forEach((cf) => {
    const currentCustomField = customFields.find(
      (customField) => customField.field === cf.field
    );
    if (currentCustomField) {
      const type = getFieldType(cf.field);
      const isDate = type?.includes("mm-dd");
      const format = type === "mm-dd" ? "MMMM D" : "MMM D, YYYY";
      const value = type === "mm-dd" ? "MM-DD" : "MM-DD-YYYY";
      const currentIcon = CUSTOM_FIELD_ICONS[currentCustomField.icon];
      const defaultIcon = CUSTOM_FIELD_ICONS.ic_text_box;

      const getContent = () => {
        switch (type) {
          case "link":
            return (
              <a
                className="underline"
                href={cf.value.includes("http") ? cf.value : "//" + cf.value}
                target="_blank"
                rel="noreferrer"
              >
                {capitalize(cf.field)}
              </a>
            );
          case "testimonial":
            return (
              <div>
                <div className="text-grey-500 text-sm">
                  {capitalize(cf.field)}
                </div>
                <a
                  className="underline"
                  href={`/contacts/${client.id}/forms/${cf.formId}`}
                >
                  {capitalize(cf.value)}
                </a>
              </div>
            );
          default:
            return (
              <div>
                <div className="text-grey-500 text-sm">
                  {capitalize(cf.field)}
                </div>
                <div>
                  {isDate ? (
                    `${moment(cf.value, value).format(format)}`
                  ) : isArray(cf.value) ? (
                    cf.value.join(", ")
                  ) : (
                    <span className="break-words">{cf.value}</span>
                  )}
                </div>
              </div>
            );
        }
      };

      items.push(
        <AboutLine Icon={currentIcon || defaultIcon}>{getContent()}</AboutLine>
      );
    }
  });

  if (!client.company && items.length < 1) return null;

  return (
    <div className="w-full border-t pt-4 group-hover:border-grey-800">
      {client.company && (
        <AboutLine Icon={CompanyIcon} label="Company">
          {client.company}
        </AboutLine>
      )}
      {items.length > 0 && <div>{items}</div>}
    </div>
  );
};

export default AboutCustomFields;
