import { FC, useMemo } from "react";
import { ClientOrganizationApi } from "@practice/sdk";
import classNames from "classnames";
import { capitalize, compact, sortBy } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { useContact } from "@hooks/data/clients";
import { useClientOrganizationMembers } from "@hooks/use-client-organization-members";
import useLabels from "@hooks/use-labels";
import { useOrganizationAccounts } from "@hooks/use-organization-accounts";
import { useSDKApiExecute } from "@hooks/use-sdk-api";
import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import { ClientType } from "@lib/data/schemas/client";

import AboutLine from "@components/About/AboutLine";
import Avatar from "@components/Client/ClientAvatar";
import ClientPicker from "@components/ClientPicker";
import { PersonInformation } from "@components/Contact";
import InfoIcon from "@components/Icons/InfoIcon";
import PlusIcon from "@components/Icons/PlusIcon";
import TeamIcon from "@components/Icons/TeamIcon";
import { LabelsList } from "@components/Labels";
import { Tooltip, TooltipVariant } from "@components/Tooltips/Tooltip";

export interface ClientOrganizationAboutListProps {
  className?: string;
  client: ClientType;
  coachId: string;
  fromMessaging?: boolean;
}

const CountIcon: React.FC<{ count: number }> = ({ count }) => {
  return (
    <div className="bg-grey-900 rounded-full flex justify-center items-center w-6 h-6 font-medium text-sm">
      {count}
    </div>
  );
};

const MultiplePeopleLine: FC<{
  people: (AccountType | ClientType)[];
  onPersonClick?: (person: AccountType | ClientType) => void;
}> = ({ people, onPersonClick }) => {
  if (!people?.length) return null;
  return (
    <div className="flex">
      <div className="truncate mr-auto">
        {people.map((p) => displayNameFromContact(p)).join(", ")}
      </div>
      <Tooltip
        variant={TooltipVariant.white}
        placement="bottom-end"
        trigger={<InfoIcon />}
      >
        <div className="divide-y">
          {people.map((p) => (
            <div
              onClick={() => onPersonClick?.(p)}
              key={p.email}
              className={classNames("py-2", {
                "cursor-pointer": !!onPersonClick,
              })}
            >
              <PersonInformation person={p} />
            </div>
          ))}
        </div>
      </Tooltip>
    </div>
  );
};

const ClientOrganizationAboutList: FC<ClientOrganizationAboutListProps> = ({
  className,
  client,
}) => {
  const { oid } = useAuth();
  const { accounts } = useOrganizationAccounts();

  const { labels } = useLabels(oid);

  const router = useRouter();

  const assignees = useMemo(() => {
    return compact(
      (client.assigneeIds ?? [client.assigneeId])?.map(
        (id) => accounts?.find((a) => a.id === id)
      )
    );
  }, [accounts, client.assigneeIds, client.assigneeId]);

  const {
    data,
    mutate: mutateList,
    isValidating,
  } = useClientOrganizationMembers({
    clientId: client.id,
    oid,
  });

  const { mutate: mutateContact } = useContact(client.id);

  const { execute: updateClientOrg, loading } = useSDKApiExecute(
    ClientOrganizationApi,
    {
      method: "updateClientOrganization",
      errorLogMessage: "Error updating organization",
      keyOrigin: "EditClientOrganizationPage",
      onSuccess: () => {
        mutateList();
        mutateContact();
      },
    }
  );

  const members = data?.members;

  const onClientSelected = async (clientId: string) => {
    if (clientId === "NEW_CONTACT") {
      router.push(`/contacts/create?clientOrganizationId=${client.id}`);
    } else if (clientId) {
      await updateClientOrg({
        clientOrganizationId: client.id,
        organizationId: oid!,
        updateClientOrganizationRequest: {
          memberIds: [...(members ?? []).map((c) => c.id), clientId],
        },
      });
    }
  };

  const primaryContact = useMemo(() => {
    return members?.find((a) => a.id === client.primaryContactId!);
  }, [members, client.primaryContactId]);

  const billingContact = useMemo(() => {
    return members?.find((a) => a.id === client.billingContactId!);
  }, [members, client.billingContactId]);

  return (
    <div className={classNames(className)}>
      <AboutLine label="Record type" Icon={TeamIcon}>
        {capitalize(client.clientType)}
      </AboutLine>
      <AboutLine
        label="Assignees"
        Icon={() => <CountIcon count={client.assigneeIds?.length || 3} />}
      >
        <MultiplePeopleLine people={assignees} />
      </AboutLine>
      <div className="border-t border-grey-900 mb-2" />

      <AboutLine
        label="Primary contact"
        Icon={() => <Avatar size="xsmall" client={primaryContact} />}
      >
        {displayNameFromContact(primaryContact)}
      </AboutLine>
      <AboutLine
        label="Billing contact"
        Icon={() => <Avatar size="xsmall" client={billingContact} />}
      >
        {displayNameFromContact(billingContact)}
      </AboutLine>

      {members && (
        <div
          className={classNames(
            "flex items-center w-full flex-1 py-2",
            loading && "opacity-50 cursor-none"
          )}
        >
          <ClientPicker
            handleClientSelection={onClientSelected}
            excludedContacts={members.map((c) => c.id)}
            customListboxClassNames="!shadow-none"
            noLabel
            className="flex-1"
            customPlaceholder={
              <div className="flex items-center bg-grey-950 cursor-pointer">
                <PlusIcon className="w-6 h-6 rounded-full bg-grey-900 ml-1 mr-3" />
                <div className="flex items-center w-full">Link client</div>
              </div>
            }
            customAddButton
            allowCreateNewClient
          />
        </div>
      )}
      <AboutLine
        label={`${capitalize(client.clientType)} members`}
        Icon={() => <CountIcon count={members?.length} />}
        className={classNames(isValidating && "opacity-50")}
      >
        {sortBy(members, displayNameFromContact)?.map((member) => {
          const clientLabels = labels?.filter(
            (label) => member.labels?.includes(label.id)
          );
          return (
            <div key={member.id} className="mb-1">
              <Link
                href={`/contacts/${member.id}`}
                key={member.id}
                className="flex items-center"
              >
                <div className="flex flex-col overflow-hidden">
                  <div className="truncate">
                    {displayNameFromContact(member)}
                  </div>
                  <div className="truncate text-grey-500">{member.email}</div>
                </div>
              </Link>
              <LabelsList labels={clientLabels} itemClassName="text-xs" />
            </div>
          );
        })}
      </AboutLine>
    </div>
  );
};

export default ClientOrganizationAboutList;
