import { FC, useState } from "react";
import axios from "axios";
import { mutate } from "swr";

import useSnackbar from "@hooks/use-snackbar";
import analytics from "@lib/analytics";

import PencilIcon from "@components/Icons/PencilIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import MoreMenu, { OptionType } from "@components/Menu/MoreMenu";

import RenameTodoModal from "./RenameTodoModal";

interface TodosMoreMenuProps {
  className?: string;
  title: string;
  clientId: string;
  coachId: string;
  iconClassName?: string;
}

const TodosMoreMenu: FC<TodosMoreMenuProps> = ({
  className,
  title,
  clientId,
  coachId,
  iconClassName,
}) => {
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const snackbar = useSnackbar();

  const options: OptionType[] = [
    {
      children: "Rename list",
      icon: PencilIcon,
      onClick: () => setShowRenameModal(true),
      separator: true,
    },
    {
      className: "text-error",
      children: "Delete completed items",
      icon: TrashIcon,
      onClick: async () => {
        try {
          await axios.delete(
            `/api/v1/users/${coachId}/todos/delete-all-completed?clientId=${clientId}`
          );
          mutate(`/api/v1/users/${coachId}/todos?clientId=${clientId}`);
          analytics.track("all_completed_todos_deleted");
          snackbar.showMessage("Completed items deleted");
        } catch (error) {
          analytics.track("all_completed_todos_deleted_error");
          snackbar.showWarning(
            "Error deleting completed items",
            error?.message || "something went wrong"
          );
        }
      },
    },
  ];

  return (
    <>
      <MoreMenu
        name="todos"
        className={className}
        options={options}
        iconClassName={iconClassName}
      />
      {showRenameModal && (
        <RenameTodoModal
          show={true}
          toggleShow={setShowRenameModal}
          title={title}
          clientId={clientId}
          coachId={coachId}
        />
      )}
    </>
  );
};

export default TodosMoreMenu;
